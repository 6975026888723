import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/docs.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "code",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#code",
        "aria-label": "code permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Code`}</h1>
    <p><a parentName="p" {...{
        "href": "https://github.com/vexchange/governance"
      }}><inlineCode parentName="a">{`governance`}</inlineCode></a></p>
    <h1 {...{
      "id": "documentation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#documentation",
        "aria-label": "documentation permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Documentation`}</h1>
    <p>{`For reference material on the Uniswap Governance system please see `}<a parentName="p" {...{
        "href": "https://uniswap.org/docs/v2/governance/governance-reference"
      }}>{`Governance Reference`}</a>{`.`}</p>
    <h1 {...{
      "id": "uni-address",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#uni-address",
        "aria-label": "uni address permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`UNI Address`}</h1>
    <p><inlineCode parentName="p">{`Uni`}</inlineCode>{` is deployed at `}<inlineCode parentName="p">{`0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984`}</inlineCode>{` on the Ethereum `}<a parentName="p" {...{
        "href": "https://etherscan.io/address/0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984"
      }}>{`mainnet`}</a>{`, and the `}<a parentName="p" {...{
        "href": "https://ropsten.etherscan.io/address/0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984"
      }}>{`Ropsten`}</a>{`, `}<a parentName="p" {...{
        "href": "https://rinkeby.etherscan.io/address/0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984"
      }}>{`Rinkeby`}</a>{`, `}<a parentName="p" {...{
        "href": "https://goerli.etherscan.io/address/0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984"
      }}>{`Görli`}</a>{`, and `}<a parentName="p" {...{
        "href": "https://kovan.etherscan.io/address/0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984"
      }}>{`Kovan`}</a>{` testnets. It was built from commit `}<a parentName="p" {...{
        "href": "https://github.com/Uniswap/governance/commit/ab22c084bacb2636a1aebf9759890063eb6e4946"
      }}>{`ab22c08`}</a>{`.`}</p>
    <h2 {...{
      "id": "abi",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#abi",
        "aria-label": "abi permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ABI`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`import Uni from '@uniswap/governance/build/Uni.json'
`}</code></pre>
    <p><a parentName="p" {...{
        "href": "https://unpkg.com/@uniswap/governance@1.0.2/build/Uni.json"
      }}>{`https://unpkg.com/@uniswap/governance@1.0.2/build/Uni.json`}</a></p>
    <h1 {...{
      "id": "timelock",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#timelock",
        "aria-label": "timelock permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Timelock`}</h1>
    <p><inlineCode parentName="p">{`Timelock`}</inlineCode>{` is deployed at `}<inlineCode parentName="p">{`0x1a9C8182C09F50C8318d769245beA52c32BE35BC`}</inlineCode>{` on the Ethereum `}<a parentName="p" {...{
        "href": "https://etherscan.io/address/0x1a9C8182C09F50C8318d769245beA52c32BE35BC"
      }}>{`mainnet`}</a>{`, and the `}<a parentName="p" {...{
        "href": "https://ropsten.etherscan.io/address/0x1a9C8182C09F50C8318d769245beA52c32BE35BC"
      }}>{`Ropsten`}</a>{`, `}<a parentName="p" {...{
        "href": "https://rinkeby.etherscan.io/address/0x1a9C8182C09F50C8318d769245beA52c32BE35BC"
      }}>{`Rinkeby`}</a>{`, `}<a parentName="p" {...{
        "href": "https://goerli.etherscan.io/address/0x1a9C8182C09F50C8318d769245beA52c32BE35BC"
      }}>{`Görli`}</a>{`, and `}<a parentName="p" {...{
        "href": "https://kovan.etherscan.io/address/0x1a9C8182C09F50C8318d769245beA52c32BE35BC"
      }}>{`Kovan`}</a>{` testnets. It was built from commit `}<a parentName="p" {...{
        "href": "https://github.com/Uniswap/governance/commit/ab22c084bacb2636a1aebf9759890063eb6e4946"
      }}>{`ab22c08`}</a>{`.`}</p>
    <h2 {...{
      "id": "abi-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#abi-1",
        "aria-label": "abi 1 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ABI`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`import Timelock from '@uniswap/governance/build/Timelock.json'
`}</code></pre>
    <p><a parentName="p" {...{
        "href": "https://unpkg.com/@uniswap/governance@1.0.2/build/Timelock.json"
      }}>{`https://unpkg.com/@uniswap/governance@1.0.2/build/Timelock.json`}</a></p>
    <h1 {...{
      "id": "governoralpha",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#governoralpha",
        "aria-label": "governoralpha permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`GovernorAlpha`}</h1>
    <p><inlineCode parentName="p">{`GovernorAlpha`}</inlineCode>{` is deployed at `}<inlineCode parentName="p">{`0x5e4be8Bc9637f0EAA1A755019e06A68ce081D58F`}</inlineCode>{` on the Ethereum `}<a parentName="p" {...{
        "href": "https://etherscan.io/address/0x5e4be8Bc9637f0EAA1A755019e06A68ce081D58F"
      }}>{`mainnet`}</a>{`, and the `}<a parentName="p" {...{
        "href": "https://ropsten.etherscan.io/address/0x5e4be8Bc9637f0EAA1A755019e06A68ce081D58F"
      }}>{`Ropsten`}</a>{`, `}<a parentName="p" {...{
        "href": "https://rinkeby.etherscan.io/address/0x5e4be8Bc9637f0EAA1A755019e06A68ce081D58F"
      }}>{`Rinkeby`}</a>{`, `}<a parentName="p" {...{
        "href": "https://goerli.etherscan.io/address/0x5e4be8Bc9637f0EAA1A755019e06A68ce081D58F"
      }}>{`Görli`}</a>{`, and `}<a parentName="p" {...{
        "href": "https://kovan.etherscan.io/address/0x5e4be8Bc9637f0EAA1A755019e06A68ce081D58F"
      }}>{`Kovan`}</a>{` testnets. It was built from commit `}<a parentName="p" {...{
        "href": "https://github.com/Uniswap/governance/commit/ab22c084bacb2636a1aebf9759890063eb6e4946"
      }}>{`ab22c08`}</a>{`.`}</p>
    <h2 {...{
      "id": "abi-2",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#abi-2",
        "aria-label": "abi 2 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ABI`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`import GovernorAlpha from '@uniswap/governance/build/GovernorAlpha.json'
`}</code></pre>
    <p><a parentName="p" {...{
        "href": "https://unpkg.com/@uniswap/governance@1.0.2/build/GovernorAlpha.json"
      }}>{`https://unpkg.com/@uniswap/governance@1.0.2/build/GovernorAlpha.json`}</a></p>
    <h1 {...{
      "id": "miscellaneous-addresses",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#miscellaneous-addresses",
        "aria-label": "miscellaneous addresses permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Miscellaneous Addresses`}</h1>
    <p><strong parentName="p">{`The following addresses only exist on the Ethereum mainnet.`}</strong></p>
    <p>{`The UNI merkle distributor address is `}<inlineCode parentName="p">{`0x090D4613473dEE047c3f2706764f49E0821D256e`}</inlineCode>{`.`}</p>
    <p>{`The staking rewards factory address is `}<inlineCode parentName="p">{`0x3032Ab3Fa8C01d786D29dAdE018d7f2017918e12`}</inlineCode>{`.`}</p>
    <p>{`The four staking rewards addresses are:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`0x6c3e4cb2e96b01f4b866965a91ed4437839a121a
0x7fba4b8dc5e7616e59622806932dbea72537a56b
0xa1484c3aa22a66c62b77e0ae78e15258bd0cb711
0xca35e32e7926b96a9988f61d510e038108d8068e
`}</code></pre>
    <p>{`The four year-long vesting contract addresses are:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`0x4750c43867ef5f89869132eccf19b9b6c4286e1a
0xe3953d9d317b834592ab58ab2c7a6ad22b54075d
0x4b4e140d1f131fdad6fb59c13af796fd194e4135
0x3d30b1ab88d487b0f3061f40de76845bec3f1e94
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`feeToSetterVester`}</inlineCode>{` address is `}<inlineCode parentName="p">{`0x18e433c7Bf8A2E1d0197CE5d8f9AFAda1A771360`}</inlineCode>{`.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`feeTo`}</inlineCode>{` address is `}<inlineCode parentName="p">{`0xDAF819c2437a82f9e01f6586207ebF961a7f0970`}</inlineCode>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      